<template>
  <div style="display: none" class="view-home">
    <div class="container questions-wrap pt-5">
      <div class="questions-title">
        <h3 class="text-primary">{{ $t('completed.title') }}</h3>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-md-6">
          <div class="completed-bg"></div>
        </div>
        <div class="col-12 col-md-6 py-5 text-center">
          <h3 class="completed-title py-3">{{ $t('completed.completed-thanks') }}</h3>
          <p class="my-3">{{ $t('completed.completed-desc') }}</p>
          <a href="#" @click.prevent="goToCabinet" class="btn btn-primary">{{ $t('completed.go-to-cabinet') }}</a>
          <p class="my-3 text-danger">{{ $t('completed.call-center-title') }}</p>
          <a href="tel:88000804848" class="btn btn-light completed-link">
            <img src="/images/phone-icon.svg" class="mr-2">8 800 080 48 48
          </a>
          <div class="hint">{{ $t('completed.call-center-hint') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Completed",
  data() {
    return {
      project_id: null
    }
  },
  mounted() {
    this.goToCabinet()
    const { project_id } = JSON.parse(localStorage.getItem('quiz'))
    this.project_id = project_id
    if(localStorage.getItem('display_results')==0){
      window.location.href = this.project_id == 1 ?  `${window.CABINET_ROOT}` : `${window.QABILET_ROOT}`;
    }
    else
    {
      window.location.href = this.project_id == 1 ? `${window.CABINET_ROOT}/testing` : `${window.QABILET_ROOT}/testing`
    }

  },
  methods: {
    goToCabinet(){
      let quiz= JSON.parse(localStorage.getItem('quiz'))
      if(localStorage.getItem('display_results')==1){
        if(quiz.start_age<=7 && quiz.end_age>= 9){
          window.location.href=`${window.QABILET_ROOT}`+'/test-results7-9?id='+localStorage.getItem('user_quiz_id')
        }
        else {
          window.location.href=`${window.QABILET_ROOT}`+'/test-results?id='+localStorage.getItem('user_quiz_id')

        }
      }
      else{
        window.location.href = this.project_id == 1 ? `${window.QABILET_ROOT}/testing` : `${window.CABINET_ROOT}/testing`
      }
      localStorage.removeItem('avatar');
      localStorage.removeItem('avatar-base64');
      localStorage.removeItem('quiz');
      localStorage.removeItem('timer');
      localStorage.removeItem('quiz-token');
      localStorage.removeItem('results');
      localStorage.removeItem('saved-answers');
      localStorage.removeItem('identification');
    }
  },
}
</script>

<style scoped>
</style>
